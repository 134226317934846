(function () {
  'use strict';

  angular
    .module('events.vehicles.guests')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.vehicles.guests', {
        abstract: true,
        url: '/:vehicleId/guests',
        templateUrl: 'events/vehicles/guests/guests.tpl.html'
      })
    ;
  }
}());
